import PropTypes from 'prop-types';
import React, { createRef } from 'react';

import classNames from 'classnames';
import debounce from 'lodash/debounce';
import noop from 'lodash/noop';
import { Manager, Popper, Reference } from 'react-popper';
import scrollToElement from 'scroll-to-element';

import { FocusTrap } from '@/ducks/a11y/components/FocusTrap';
import { FormattedMessageContext } from '@/helpers/formatted-message';
import { withIsMobile } from '@/hocs/withIsDevice';
import { isInsideEvent } from '@/hooks/useClickOutside';

import { FadeIn } from '../Animations';
import ArrowLeft from '../Icon/ArrowLeft';
import CloseWithoutBackground from '../Icon/ChooseVoyage/CloseWithoutBackground';
import modifiers from './modifiers';

/**
 * Popover Component
 * Creates a container for content which is placed adjacent to a Reference component.
 * @section Elements
 */

const propTypes = {
  applyNewFilterUI: PropTypes.bool,
  ariaLabel: PropTypes.string,
  /** Applies an opaque background when the popover is open. */
  backdrop: PropTypes.bool,
  /** The container to bound the Popper to, either a node element or string id. */
  boundary: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(PropTypes.element)]),
  /** The contents of the Popper. */
  children: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  /** The event name to listen on the Reference to trigger opening. */
  event: PropTypes.string,
  externalTriggerRef: PropTypes.object,
  /* Wrapper Class for Filter Popover */
  filterClass: PropTypes.string,
  fullscreen: PropTypes.bool,
  hasFocusTrap: PropTypes.bool,
  id: PropTypes.string,
  isHideScroll: PropTypes.bool,
  keepReferenceFocusAfterClose: PropTypes.bool,
  /** Event fired when the Popover is dismissed. */
  onDismiss: PropTypes.func,
  /** Event fired when the Popover is triggered. */
  onEvent: PropTypes.func,
  onRenderChange: PropTypes.func,
  onResetIconClick: PropTypes.func,
  /** The ideal position of the Popper. It will readjust depending on the boundary. */
  position: PropTypes.oneOf([
    'top',
    'top-start',
    'top-end',
    'left',
    'left-start',
    'left-end',
    'bottom',
    'bottom-start',
    'bottom-end',
    'right',
    'right-start',
    'right-end',
  ]),
  /** Additional icon to place after reference */
  refIcon: PropTypes.node,
  /** The Reference element that the Popover is placed against. */
  reference: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  renderMainFilter: PropTypes.bool,
  showItineraries: PropTypes.bool,
  tabIndex: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  toggleAdvancedRefinement: PropTypes.bool,
};

let totalCountOfPopovers = 0;

class Popover extends React.Component {
  static defaultProps = {
    applyNewFilterUI: false,
    ariaLabel: null,
    backdrop: false,
    boundary: typeof document !== 'undefined' ? document.body : null,
    disabled: false,
    event: 'click',
    externalTriggerRef: null,
    filterClass: null,
    focusTrapDisableEvents: [],
    fullscreen: false,
    hasFocusTrap: false,
    id: null,
    isHideScroll: true,
    keepReferenceFocusAfterClose: false,
    onDismiss: null,
    onEvent: null,
    onRenderChange: noop,
    onResetIconClick: noop,
    position: 'bottom',
    refIcon: null,
    renderMainFilter: noop,
    showItineraries: true,
    tabIndex: '0',
    title: null,
    toggleAdvancedRefinement: noop,
  };
  contentRef;
  focusTrapContainerRef;
  referenceTriggerElementRef;
  handleWindowResize;

  onReferenceClickBack = () => {
    const { onDismiss, toggleAdvancedRefinement } = this.props;
    this.closePopover();
    this.removeListener();

    if (toggleAdvancedRefinement) {
      toggleAdvancedRefinement();
    }

    if (onDismiss) {
      onDismiss();
    }
  };

  onReferenceClickClose = () => {
    const { onDismiss, renderMainFilter } = this.props;
    this.closePopover();
    this.removeListener();

    if (renderMainFilter) {
      renderMainFilter();
    }

    if (onDismiss) {
      onDismiss();
    }
  };

  constructor(props) {
    super(props);

    // eslint-disable-next-line react/destructuring-assignment
    this.isMobile = this.props.isMobile;
    this.state = {};
    this.contentRef = createRef();
    this.focusTrapContainerRef = createRef();
    this.referenceTriggerElementRef = createRef();

    this.onEvent = this.onEvent.bind(this);
    this.onDismiss = this.onDismiss.bind(this);
    this.setPopoverRef = this.setPopoverRef.bind(this);
    this.setReferenceRef = this.setReferenceRef.bind(this);
    this.closePopover = this.closePopover.bind(this);
    this.scrollCurrentPosition = 0;

    this.handleWindowResize = debounce(() => this.setState({ viewportWidth: window.innerWidth }), 300);
  }
  closePopover() {
    const { onRenderChange } = this.props;

    onRenderChange(false);
    /** Removing the overlay when last popover is closed */
    totalCountOfPopovers--;
    if (totalCountOfPopovers <= 0) {
      const rootEl = document.body;
      rootEl?.classList?.remove('-refinementOpen');
      rootEl?.classList?.remove('-newFilterUI');
      rootEl?.classList?.remove('no-scroll', 'flyout-open-right'); // eslint-disable-line no-unused-expressions
      rootEl?.style?.removeProperty('border-right-width'); // eslint-disable-line no-unused-expressions
      rootEl?.style?.removeProperty('position');
      totalCountOfPopovers = 0;
    }
  }

  componentDidMount() {
    const { boundary } = this.props;
    this.setBoundary(boundary ?? document.body);
    this.setState({ viewportWidth: window.innerWidth });
    window.addEventListener('resize', this.handleWindowResize);
  }

  componentWillUnmount() {
    this.removeListener();
    window.removeEventListener('resize', this.handleWindowResize);
  }

  onDismiss(event) {
    const { onDismiss, open } = this.props;
    if (!open) {
      return null;
    }
    this.props.keepReferenceFocusAfterClose && this.referenceTriggerElementRef.current?.focus();
    if (!isInsideEvent(this.popoverRef, event) && !(open && isInsideEvent(this.contentRef.current, event))) {
      this.closePopover();
      this.removeListener();

      if (onDismiss) {
        onDismiss(event);
      }
    }
  }

  onEvent() {
    const { applyNewFilterUI, disabled, id, onEvent, onRenderChange } = this.props;

    if (disabled) {
      return;
    }

    onRenderChange(true);

    this.scrollCurrentPosition = Math.max(
      window.pageYOffset,
      document.documentElement.scrollTop,
      document.body.scrollTop,
    );
    if (this.isMobile) {
      if (id === 'CabinTypeRefinement') {
        scrollToElement('#CabinTypeRefinement', {
          offset: -125,
        });
      }
    }
    document.body.addEventListener('click', this.onDismiss);
    /** Adding the overlay when popover is open */
    if (
      id === 'CabinTypeRefinement' ||
      id === 'DateRefinement' ||
      !this.isMobile ||
      id === 'Destination' ||
      id === 'travel-party'
    ) {
      document.querySelector('body').classList.add('-refinementOpen');
      if (applyNewFilterUI) {
        document.querySelector('body').classList.add('-newFilterUI');
      }
      totalCountOfPopovers++;
    }
    if (onEvent) {
      onEvent();
    }
  }

  removeListener() {
    document.body.removeEventListener('click', this.onDismiss);
  }

  render() {
    const { boundary } = this.state;
    const {
      applyNewFilterUI,
      backdrop,
      children,
      event,
      filterClass,
      fullscreen,
      id,
      open,
      position,
      refIcon,
      reference,
      showItineraries,
      tabIndex,
      title,
    } = this.props;

    const { formatMessage } = this.context;
    const popoverClasses = classNames({
      '-fullscreen': fullscreen,
      '-newFilter': applyNewFilterUI,
      '-open': open,
      Popover: true,
    });

    const buttonId = `${id}_button`;
    return (
      <Manager>
        <div className={popoverClasses} onClick={this.onDismiss} ref={this.focusTrapContainerRef} role="presentation">
          {this.props.hasFocusTrap && this.props.open && (
            <FocusTrap
              disableEvents={this.props.focusTrapDisableEvents}
              isOpened={this.props.open}
              onClose={this.onDismiss}
              reference={this.focusTrapContainerRef.current}
            />
          )}
          {backdrop && <div className="Popover__backdrop" />}
          <div ref={this.setPopoverRef}>
            <Reference>
              {({ ref }) => {
                this.setRefContext = ref;
                const targetProps = {
                  [`on${event[0].toUpperCase()}${event.substr(1)}`]: !open ? this.onEvent : this.onReferenceClickClose,
                  // Accessibility
                  onKeyDown: (keyDownEvent) => {
                    if ([' ', 'Enter', 'Space Bar', 'Spacebar'].includes(keyDownEvent.key)) {
                      if (!open) {
                        keyDownEvent.preventDefault();
                        this.onEvent();
                      } else {
                        keyDownEvent.preventDefault();
                        this.onReferenceClickClose();
                      }
                    }
                  },
                  ref: this.setReferenceRef,
                };
                return (
                  <div
                    aria-haspopup="dialog"
                    className={filterClass}
                    id={buttonId}
                    role="button"
                    tabIndex={tabIndex}
                    {...targetProps}
                  >
                    <span className={`Popover__reference ${applyNewFilterUI ? '-newFilter' : ''}`}>{reference}</span>
                    {refIcon}
                  </div>
                );
              }}
            </Reference>
            <FadeIn in={open} mountOnEnter timeout={500} unmountOnExit>
              <Popper
                modifiers={{
                  ...modifiers,
                  preventOverflow: {
                    boundariesElement: boundary,
                  },
                }}
                placement={position}
              >
                {({ arrowProps, placement, ref, style }) => (
                  <div
                    aria-controls={buttonId}
                    className={`Popover__container ${applyNewFilterUI ? '-newFilter' : ''}`}
                    ref={ref}
                    role="dialog"
                    style={this.isMobile && window.innerWidth < 768 ? {} : style}
                    tabIndex="-1"
                    x-placement={placement}
                  >
                    <div
                      className={`Popover__arrow  ${applyNewFilterUI ? '-newFilter' : ''}`}
                      ref={arrowProps.ref}
                      style={arrowProps.style}
                    />
                    <div className="Popover__header">
                      {!showItineraries && (
                        <button
                          aria-label="Go back"
                          className="CloseBtn close__flyout"
                          onClick={this.onReferenceClickBack}
                          tabIndex={this.state.viewportWidth > 577 ? -1 : 0}
                          type="button"
                        >
                          <ArrowLeft />
                        </button>
                      )}

                      <button
                        aria-label={formatMessage({
                          defaultMessage: 'Close',
                          id: 'Popover.closeButton',
                        })}
                        className={`CloseBtn Popover__close ${applyNewFilterUI ? '-newFilter' : ''}`}
                        onClick={this.onReferenceClickClose}
                        tabIndex={this.state.viewportWidth > 768 ? -1 : 0}
                      >
                        <CloseWithoutBackground />
                      </button>
                      {title && <span className="Popover__title">{title}</span>}
                    </div>
                    <div className="Popover__content">{children(this.closePopover, this.contentRef)}</div>
                  </div>
                )}
              </Popper>
            </FadeIn>
          </div>
        </div>
      </Manager>
    );
  }

  setBoundary(boundary = document.body) {
    this.setState({
      boundary: typeof boundary === 'string' ? document.getElementById(boundary) : boundary,
    });
  }

  setPopoverRef(ref) {
    this.popoverRef = ref;
  }

  setReferenceRef(ref) {
    if (ref) {
      // Set the Managers Reference
      this.setRefContext(ref);
      this.referenceTriggerElementRef.current = ref;
      if (this.props.externalTriggerRef) {
        this.props.externalTriggerRef.current = ref;
      }
    }
  }
}

Popover.propTypes = propTypes;
Popover.contextType = FormattedMessageContext;

export default withIsMobile(Popover);
