import type { TKeyEventGroup } from '@/ducks/a11y/types';

import useFocusTrap from '@/ducks/a11y/hooks/useFocusTrap';

type TFocusTrapProps = {
  disableEvents?: TKeyEventGroup[];
  isOpened: boolean;
  onClose?: CallableFunction;
  reference: HTMLElement;
};
export const FocusTrap = ({ disableEvents, isOpened, onClose, reference }: TFocusTrapProps) => {
  useFocusTrap({
    disableEvents,
    element: reference,
    isOpened: isOpened,
    onClose: () => onClose?.(),
  });
};
