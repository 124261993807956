import { Skeleton } from '@/components/Skeleton';
import formatMoney from '@/helpers/util/formatMoney';
import isSafari from '@/helpers/util/isSafari';
import { isDiscountAmount } from '@/helpers/util/misc';

import './Price.scss';

interface AmountProps {
  amount: number;
  currencyCode?: string;
  onlySymbol?: boolean;
}

export const Amount = ({ amount, currencyCode = 'USD', onlySymbol }: AmountProps) =>
  formatMoney(amount, currencyCode, onlySymbol);

const ChooseCabinPrice = ({ amount, currencyCode }: AmountProps) => {
  const originalAmountComp = <Amount amount={amount} currencyCode={currencyCode} />;
  return (
    <span className="price">
      <span className="amount">{originalAmountComp}</span>
    </span>
  );
};

interface ChooseCabinDiscountProps extends AmountProps {
  originalAmount: number;
}

const ChooseCabinDiscount = ({ amount, currencyCode, originalAmount }: ChooseCabinDiscountProps) => {
  const isDiscountPresent = isDiscountAmount(originalAmount, amount);
  const originalAmountComp = <Amount amount={originalAmount - amount} currencyCode={currencyCode} />;
  if (isDiscountPresent) {
    return (
      <span className="price price-with-discount">
        <span className="amount">{originalAmountComp}</span>
      </span>
    );
  }

  return originalAmountComp;
};

interface PriceProps extends AmountProps {
  currencyCode: string;
  descriptionPrefix?: string;
  id?: string;
  isForceLineBreak?: boolean;
  isLoading?: boolean;
  isVariantBAndAmount?: boolean;
  isVariantBAndDiscount?: boolean;
  onlySymbol?: boolean;
  originalAmount?: number;
  withoutCurrencyCode?: boolean;
}

const Price = ({
  amount,
  currencyCode,
  descriptionPrefix,
  id,
  isForceLineBreak = false,
  isLoading,
  isVariantBAndAmount,
  isVariantBAndDiscount,
  onlySymbol,
  originalAmount = 0,
}: PriceProps) => {
  if (isLoading || isNaN(amount)) {
    return <Skeleton width="100px" />;
  }

  if (isVariantBAndAmount) {
    return <ChooseCabinPrice amount={amount} currencyCode={currencyCode} />;
  }

  if (isVariantBAndDiscount) {
    return <ChooseCabinDiscount amount={amount} currencyCode={currencyCode} originalAmount={originalAmount} />;
  }

  const isDiscountPresent = isDiscountAmount(originalAmount, amount);
  const initialAmount = <Amount amount={amount} currencyCode={currencyCode} onlySymbol={onlySymbol} />;
  const initialAmountClassName = `amount ${isForceLineBreak ? 'force-line-break' : 'same-line-prices'}`;
  let originalAmountComp = (
    <span className={initialAmountClassName}>
      <span className="sr-only">Price</span>
      {initialAmount}
    </span>
  );

  // This overwrite enables proper Voice over reading on Safari
  if (isSafari()) {
    const srOnlyText = `
    ${descriptionPrefix} 
    Price ${formatMoney(amount, currencyCode, onlySymbol)}
    ${isDiscountPresent ? 'Price without discount ' + formatMoney(originalAmount, currencyCode, onlySymbol) : ''}
  `;

    originalAmountComp = (
      <span aria-hidden="true" className={initialAmountClassName} id={id}>
        <span className="sr-only">{srOnlyText}</span>
        <span aria-hidden="true">{initialAmount}</span>
      </span>
    );
  }

  if (isDiscountPresent) {
    return (
      <span className="price price-with-discount">
        {originalAmountComp}
        <span className="original-amount">
          <span className="sr-only"> Price without discount</span>
          {formatMoney(originalAmount, currencyCode, onlySymbol)}
        </span>
      </span>
    );
  }

  return originalAmountComp;
};

export default Price;
