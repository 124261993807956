import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';

import classNames from 'classnames';
import { isArray } from 'lodash';

import Chevron from '@/components/Icon/Chevron';
import useKeyboardFocus from '@/hooks/useKeyboardFocus';

import FormControl from '../FormControl';

// TODO: Refactor this component and extract as separate components: Select, Checkbox and may be other

const TextField = (props) => {
  const [expanded, setExpanded] = useState(false);
  const [focussed, setFocussed] = useState(false);
  const [hasContent, setHasContent] = useState(!!(props.value || props.defaultValue));
  const supportedChildren = ['option'];

  const isByKeyboard = useKeyboardFocus();

  useEffect(() => {
    if (props.value !== undefined && !!props.value !== hasContent) {
      setHasContent(!!props.value);
    }
  }, [props.value, hasContent]);

  const isContainer = useCallback(() => {
    const { children } = props;
    return (
      children &&
      (React.isValidElement(children) ||
        (React.Children.count(children) > 1 && !supportedChildren.includes(children[0]?.type)))
    );
  }, [props.children]);

  const handleBlur = (event) => {
    setExpanded(false);
    setFocussed(false);
    setHasContent(!!event.target.value);
    if (props.onBlur) {
      props.onBlur(event);
    }
  };

  const handleClick = (event) => {
    const isSelect = !!React.Children.count(props.children);
    setExpanded(isSelect && !expanded);
    setHasContent(!!event.target.value);
    if (props.onClick) {
      props.onClick(event);
    }
  };

  const handleFocus = (event) => {
    setFocussed(true);
    setHasContent(!!event.target.value);
    if (props.onFocus) {
      props.onFocus(event);
    }
  };

  const {
    children = null,
    className,
    disabled,
    error,
    getRef,
    id,
    label,
    labelClassname,
    required,
    shouldActiveStateIgnoreChildren,
    subText,
    value,
  } = props;

  // If disabled option chosen, then Select is empty
  const selectedDisabledOption = Boolean(
    isArray(children) && children?.find((option) => option?.props?.value === value)?.props?.disabled === true,
  );

  const containerCheck = isContainer();
  const classes = classNames({
    TextField: true,
    active: hasContent || (!shouldActiveStateIgnoreChildren && !!children),
    [className]: !!className,
    disabled,
    empty: selectedDisabledOption,
    error: !!error,
    expanded,
    focus: focussed,
    isByKeyboard: focussed && isByKeyboard,
    'no-label': !label,
  });

  return (
    <div className={classes}>
      {label && (
        <label className={labelClassname} htmlFor={id}>
          {label}
          {required && <span className="TextField__required">*</span>}
        </label>
      )}
      {!containerCheck ? (
        <div className="TextField__input">
          <FormControl
            {...props}
            component={children ? 'select' : 'input'}
            getRef={getRef}
            id={id}
            native
            onBlur={handleBlur}
            onClick={handleClick}
            onFocus={handleFocus}
          />
          {children && (
            <div className="TextField__caret">
              <Chevron />
            </div>
          )}
        </div>
      ) : (
        <div className="TextField__container">{children}</div>
      )}
      {subText && <span className="TextField__subText">{subText}</span>}
    </div>
  );
};

const propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  getRef: PropTypes.func,
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  labelClassname: PropTypes.string,
  required: PropTypes.bool,
  shouldActiveStateIgnoreChildren: PropTypes.bool,
  subText: PropTypes.node,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

TextField.propTypes = propTypes;

export default TextField;
