import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import CircleClose from '@/components/Icon/CircleClose';
import { addBodyBackgroundClasses, clearBodyBackgroundClasses } from '@/helpers/util';
import { useIsMobile } from '@/hooks/useIsDevice';

import FilterPill, { propTypes as pillPropTypes } from '../FilterPill';
import FilterPopover, { propTypes as popoverPropTypes } from '../FilterPopover';

import './Filter.scss';

const filterClass = 'filter-pill-container';

const Filter = ({
  applyNewFilterUI,
  boundary,
  children,
  className,
  focusTrapDisableEvents,
  getMultipleCabinsNewVersion,
  hasFocusTrap,
  keepPopoverReferenceFocusAfterClose,
  label,
  newFilterDestination,
  onEvent,
  pill,
  popover,
  position,
  showItineraries,
  tabIndex,
  toggleAdvancedRefinement,
}) => {
  const isMobile = useIsMobile();

  const onEventWrapper = () => {
    if (isMobile) {
      addBodyBackgroundClasses('right', true);
    }
    onEvent?.();
  };

  const onDismissWrapper = (event) => {
    if (isMobile) {
      clearBodyBackgroundClasses('right', true);
    }
    popover.onDismiss?.(event);
  };

  const reference = (
    <FilterPill
      applyNewFilterUI={applyNewFilterUI}
      disabled={pill.disabled}
      icon={pill.icon}
      label={pill.label}
      state={pill.state}
      title={pill.title}
      variant={pill.variant}
    />
  );

  const refIcon = useMemo(() => {
    if (pill.state !== 'applied') {
      return null;
    }

    const handleResetIconClick = (event) => {
      pill.onResetIconClick();
      event.stopPropagation();
    };
    const handleKeyDown = (event) => {
      if (event.key) {
        switch (event.key) {
          case 'Enter':
          case 'NumpadEnter':
          case 'Space':
            break;
          default:
            return;
        }
      }
      pill.onResetIconClick();
    };

    return (
      <span
        aria-label="close"
        className="offset-reset-icon"
        onClick={handleResetIconClick}
        onKeyDown={handleKeyDown}
        role="button"
        tabIndex="0"
      >
        <CircleClose />
      </span>
    );
  }, [pill]);

  return (
    <div aria-label={label} className="filter">
      <FilterPopover
        applyNewFilterUI={applyNewFilterUI}
        ariaLabel={popover.ariaLabel}
        bodyclass={popover.bodyclass}
        boundary={boundary}
        btnClassName={className}
        className={popover.className}
        disabled={popover.disabled}
        displayReset={popover.displayReset}
        filterClass={filterClass}
        focusTrapDisableEvents={focusTrapDisableEvents}
        getMultipleCabinsNewVersion={getMultipleCabinsNewVersion}
        hasFocusTrap={hasFocusTrap}
        headerIcon={applyNewFilterUI ? pill.headerIcon : null}
        id={popover.id}
        isApplyDisabled={popover.isApplyDisabled}
        keepPopoverReferenceFocusAfterClose={keepPopoverReferenceFocusAfterClose}
        newFilterDestination={newFilterDestination}
        onDismiss={onDismissWrapper}
        onEvent={onEventWrapper}
        onRenderChange={popover.onRenderChange}
        onReset={popover.onReset}
        onSubmit={popover.onSubmit}
        open={popover.open}
        position={position}
        refIcon={refIcon}
        reference={reference}
        renderMainFilter={popover.renderMainFilter}
        shResetDisplay={popover.shResetDisplay}
        showItineraries={showItineraries}
        tabIndex={tabIndex}
        title={popover.title}
        toggleAdvancedRefinement={toggleAdvancedRefinement}
      >
        {children}
      </FilterPopover>
    </div>
  );
};

Filter.propTypes = {
  applyNewFilterUI: PropTypes.bool,
  boundary: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(PropTypes.element)]),
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  getMultipleCabinsNewVersion: PropTypes.func,
  hasFocusTrap: PropTypes.bool,
  label: PropTypes.string,
  onEvent: PropTypes.func,
  pill: PropTypes.shape(pillPropTypes).isRequired,
  popover: PropTypes.shape(popoverPropTypes).isRequired,
  position: PropTypes.string,
  showItineraries: PropTypes.bool,
  tabIndex: PropTypes.number,
  toggleAdvancedRefinement: PropTypes.func,
};

Filter.defaultProps = {
  applyNewFilterUI: false,
  boundary: typeof document !== 'undefined' ? document.body : null,
  className: '',
  getMultipleCabinsNewVersion: () => {},
  hasFocusTrap: false,
  keepPopoverReferenceFocusAfterClose: false,
  label: null,
  onEvent: () => {},
  position: 'bottom',
  showItineraries: true,
  tabIndex: 0,
  toggleAdvancedRefinement: () => {},
};

export default Filter;
