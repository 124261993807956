import PropTypes from 'prop-types';
import React from 'react';

import { FormattedMessage } from '@/helpers/formatted-message';
import { FiltersPriceType } from '@/infra/types/common/filters';

const propTypes = {
  itineraryDetails: PropTypes.bool,
  priceType: PropTypes.string.isRequired,
  suite: PropTypes.bool,
};

const defaultProps = {
  itineraryDetails: false,
  suite: false,
};

const PriceFootnote = ({ itineraryDetails, priceType, suite }) => {
  let priceTypeLabel = priceType;

  if (priceType === FiltersPriceType.perCabin && suite) {
    priceTypeLabel = 'perSuite';
  }
  return itineraryDetails ? (
    <p className="PriceFootnote">
      <FormattedMessage defaultMessage="per sailor" id={`PriceFootnote.flyout.${priceTypeLabel}`} />
    </p>
  ) : (
    <FormattedMessage defaultMessage="starting price" id={`PriceFootnote.${priceTypeLabel}`} />
  );
};

PriceFootnote.propTypes = propTypes;
PriceFootnote.defaultProps = defaultProps;

export default PriceFootnote;
