import getSymbolFromCurrenciesData from './currency/currencySymbols';

const formatMoney = (amount: number, currencyCode: string, onlySymbol?: boolean): string => {
  const fractionDigits = Number.isInteger(amount) ? 0 : 2;
  return [
    amount < 0 ? '-' : '',
    getSymbolFromCurrenciesData(currencyCode, onlySymbol),
    Math.abs(amount).toLocaleString('en-US', {
      maximumFractionDigits: fractionDigits,
      minimumFractionDigits: fractionDigits,
    }),
  ].join('');
};

export default formatMoney;
